<template>
  <div class="profile">profile</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({}),
  },
  mounted() {},
  methods: {
    ...mapActions({
      getProfileOrders: 'getProfileOrders',
    }),
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';
</style>
